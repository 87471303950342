$(document).ready(loadReport)

function loadReport() {
  let datatableDiv = document.querySelector('.datatable');
  if (datatableDiv === null || !('recordsId' in datatableDiv.dataset)) {
    return;
  };
  let recordsId = datatableDiv.dataset.recordsId;
  let filename = datatableDiv.dataset.filename;

  checkReportProgress(recordsId, filename, loadRecords, loadError);
};

export function checkReportProgress(recordsId, filename, success, failure) {
  $.getJSON('/reports/progress/' + recordsId, function(progressJson) {
    //if progress is not a json
    //error
    //return
    let progress = progressJson['progress'];
    if (progress >= 100) {
      //success, complete
      $.getJSON('/reports/records/' + recordsId, function(records) {
        success(records, filename);
      });
      return;
    }
    if (progress < 0) {
      //error
      failure();
      return;
    }

    setTimeout(checkReportProgress, 1000, recordsId, filename, success, failure);
  });
};

export function loadError() {
  $('#loading').hide();
  $('#error').show();
};

function loadRecords(reportJson, filename) {
    $('#loading').hide();
    $('#report').show();
    $('.datatable').DataTable($.extend({}, datatableOptions(filename), reportJson));
};

export function datatableOptions(filename = 'PIMMS') {
  let buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, column, row, node) {
          // Strip (-)$ and , from columns to make it numeric
          let cleanData = data.replace(/(<([^>]+)>)/g, '')
          return cleanData.match(/^-?\$/) ? cleanData.replace(/[$,]/g, '') : cleanData;
        }
      }
    }
  };

  return {
    buttons: [
      'colvis',
      $.extend(true, {}, buttonCommon, {
        extend: 'csvHtml5',
        footer: false,
        filename: filename
      }),
      $.extend(true, {}, buttonCommon, {
        extend: 'excelHtml5',
        footer: false,
        filename: filename
      }),
    ],
    columnDefs: [{
      "targets": "hide_column",
      "visible": false
    },
    {
      "targets": "currency",
      "render": $.fn.dataTable.render.number( ',', '.', 2, '$' )
    },
    { "targets": ["_all"],
      "render": function (data, type, full, meta)  {
        if (typeof data === "string" || data instanceof String || data === null) {
          return data;
        } else if (data.type == "email") {
          return "<a href=\"mailto:" + data.value + "\">" + data.value + "</a>";
        } else if (data.type == "link") {
          return "<a href=\"" + data.href + "\">" + data.value + "</a>";
        }
      }
    }],
    dom: '<"row"<"col-sm-6"B><"col-sm-6"f>>rt<"row"<"col-sm-6"i><"col-sm-6"p>>',
    fixedHeader: true,
    pageLength: 20
  };
}
